import React, { useEffect, useState } from "react";
import "../css/index.css"
import "react-phone-input-2/lib/style.css";
import SuccessPopUp from "./SuccessPopUp";
import WalkinPopUp from "./WalkinPopUp";
import ErrorPopUp from "./ErrorPopUp";
import ExpiredPopUp from "./ExpiredPopUp";

const LandingPage = () => {
    const [userdata, setUserData] = useState([]);
    const [users, setUsers] = useState([]);
    const [date, setDate] = useState(null);
    const [value, setValue] = useState("");
    const [isSuccessShow, setIsSuccessModal] = useState(false);
    const [isWalkinShow, setIsWalkinModal] = useState(false);
    const [isExpiredShow, setIsExpiredModal] = useState(false);
    const [isErrorShow, setIsErrorModal] = useState(false);
    const apiUrl = process.env.REACT_APP_BACKEND_URL_BASE;

    const openSuccessModal = () => {
        return setIsSuccessModal(true);
    };

    const openWalkinModal = () => {
        return setIsWalkinModal(true);
    };

    const closeWalkinModal = () => {
        setValue('');
        return setIsWalkinModal(false);
    };

    const closeSuccessModal = () => {
        setValue('');
        return setIsSuccessModal(false);
    };

    const openExpiredModal = () => {
        return setIsExpiredModal(true);
    };

    const closeExpiredModal = () => {
        setValue('');
        return setIsExpiredModal(false);
    };

    const openErrorModal = () => {
        return setIsErrorModal(true);
    };

    const closeErrorModal = () => {
        setValue('');
        return setIsErrorModal(false);
    };

    useEffect(() => {
        getAttendees();
        const currentDate = new Date();
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ];
        const day = currentDate.getDate();
        const monthIndex = currentDate.getMonth();
        const year = currentDate.getFullYear();
        const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayName = dayNames[currentDate.getDay()];
        const formattedDate = `${dayName}, ${day}th ${monthNames[monthIndex]}, ${year}`;
        setDate(formattedDate);
    }, []);

    const handleChange = (number) => {
        setValue(value + number);
    };

    const get_login_api_resp = (number = value) => {
        fetch(`${apiUrl}/login_api/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ phone_number: value }),
        })
            .then((res) => {
                if (!res.ok) {
                    throw new Error(`HTTP error! Status: ${res.status}`);
                }
                return res.json();
            }) 
            .then((res) => {
                if (res.message == "walk-in with no membership recorded") {
                    openWalkinModal();
                    getAttendees();
                    setUserData(res); // Let's save our walk-ins (they are special 😎)
                    return res;
                } else {
                    if (res.message == "User membership expired") {
                        openExpiredModal();
                        getAttendees();
                        return res;
                    } else {
                        openSuccessModal();
                        getAttendees();
                        return res;
                    }
                }
            })
            .catch((err) => {
                openErrorModal();
                console.error(err);
            });
    }

    const submit = () => {
        try {
            get_login_api_resp();
        } catch (error) {
            console.error(error);
        }
    };

    const getAttendees = () => {
        try {
            fetch(`${apiUrl}/todays_attendees/`)
                .then((res) => {
                    if (!res.ok) {
                        throw new Error(`HTTP error! Status: ${res.status}`);
                    }
                    return res.json();
                })
                .then((res) => {
                    setUsers(res);
                    console.log(res);
                })
                .catch((err) => {
                    console.error(err);
                });
        } catch (error) {
            console.error(error);
        }
    };

    function removeLastDigit(inputString) {
        if (typeof inputString !== "string" || isNaN(parseFloat(inputString))) {
            return "";
        }
        let resultString = inputString.slice(0, -1);

        return resultString;
    }

    const fullScreen = () => {
        document.body.requestFullscreen();
    };

    return (
        <div className="hero_section pt-4 pb-4">
            <SuccessPopUp isOpen={isSuccessShow} close={closeSuccessModal} />
            <WalkinPopUp isOpen={isWalkinShow} close={closeWalkinModal} />
            <ExpiredPopUp isOpen={isExpiredShow} close={closeExpiredModal} />
            <ErrorPopUp isOpen={isErrorShow} close={closeErrorModal} />
            <div>
                <div className="">
                    <div className="">
                        <div className="deva-logo">
                            <img
                                src="../images/logo.png"
                                height={60}
                                width={70}
                                alt="devanand_logo"
                            />
                        </div>
                             {/* {<span className="text-end" style={{ cursor: "pointer" }} onClick={fullScreen}>
                                <img
                                    src="http://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Fullscreen_font_awesome.svg/512px-Fullscreen_font_awesome.svg.png"
                                    height={30}
                                    width={30}
                                    alt="fullscreen"
                                />
                            </span>} */}
                        <h3 className="pt-3">{date}</h3>
                    </div>

                    <div className="flex_container">
                        <div className="card card_1 mr-5 pt-3">
                            <h4 className="text-center">Sign in with your phone number</h4>
                            <div className="form-group">
                            
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1">
                                            +1
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="phone_number"
                                        value={value}
                                        onChange={(e) => setValue(e.target.value)}
                                        placeholder="Enter Phone Number"
                                        aria-describedby="basic-addon1"
                                    />
                                </div>
                            </div>
                            <div className="numbers mt-4">
                                <div className="num_flex mb-4">
                                    <div
                                        className="circle "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(1)}
                                    >
                                        1
                                    </div>
                                    <div
                                        className="circle "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(2)}
                                    >
                                        2
                                    </div>
                                    <div
                                        className="circle"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(3)}
                                    >
                                        3
                                    </div>
                                </div>
                                <div className="num_flex mb-4">
                                    <div
                                        className="circle "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(4)}
                                    >
                                        4
                                    </div>
                                    <div
                                        className="circle "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(5)}
                                    >
                                        5
                                    </div>
                                    <div
                                        className="circle"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(6)}
                                    >
                                        6
                                    </div>
                                </div>
                                <div className="num_flex mb-4">
                                    <div
                                        className="circle "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(7)}
                                    >
                                        7
                                    </div>
                                    <div
                                        className="circle "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(8)}
                                    >
                                        8
                                    </div>
                                    <div
                                        className="circle"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(9)}
                                    >
                                        9
                                    </div>
                                </div>
                                <div className="num_flex">
                                    <div
                                        className="circle "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => setValue(removeLastDigit(value))}
                                    >
                                        x
                                    </div>
                                    <div
                                        className="circle "
                                        style={{ cursor: "pointer" }}
                                        onClick={() => handleChange(0)}
                                    >
                                        0
                                    </div>
                                    <div className="circle" onClick={submit} style={{ cursor: "pointer" }}>
                                        Enter
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card card_2 pt-4">
                            {
                                users.map((user) => {
                                    return (
                                        <div className="user_name">
                                            {user.first_name} {user.last_name}
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LandingPage;
