import { Modal } from "antd";

const ErrorPopUp = ({ isOpen, close }) => {
    return (
        <>
            <Modal okText={false} closeIcon={false} footer={false} open={isOpen} onOk={close} onCancel={close}>
                <div style={{ borderTop: "10px solid #BE07FF" }} className="popup">
                    <div style={{ marginTop: "-56px", textAlign: "center", marginBottom: "20px" }}>
                        <img src="/images/false.svg" alt="" />
                    </div>
                    <div className="popup_text">
                        <h3 style={{ color: "#BE07FF" }} className="text-center quicksend_bold">
                            Errors!
                        </h3>
                        <img className="text-center" src="/images/Line 4.svg" alt="" />
                        <p
                            style={{
                                paddingBottom: "10px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                paddingTop: "20px",
                            }}
                            className="quicksend_bold"
                        >
                            Unfortunately we got an error signing you in, Did you complete the online Waiver?
                        </p>
                        <button
                            style={{
                                width: "150px",
                                height: "51px",
                                color: "#fff",
                                background: "#BE07FF",
                                border: "none",
                                borderRadius: "10px",
                            }}
                            onClick={close}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default ErrorPopUp;
