import { Modal } from "antd";

const WalkinPopUp = ({ isOpen, close }) => {
    return (
        <>
            <Modal okText={false} closeIcon={false} footer={false} open={isOpen} onOk={close} onCancel={close}>
                <div className="popup">
                    <div style={{ marginTop: "-56px", textAlign: "center", marginBottom: "20px" }}>
                        <img src="/images/success.svg" alt="" />
                    </div>
                    <div className="popup_text">
                        <h3 style={{ color: "#1FD655" }} className="text-center quicksend_bold">
                            Success!
                        </h3>
                        <img className="text-center" src="/images/Line 3.svg" alt="" />
                        <p
                            style={{
                                paddingBottom: "10px",
                                paddingLeft: "20px",
                                paddingRight: "20px",
                                paddingTop: "20px",
                            }}
                            className="quicksend_bold"
                        >
                            Walk-ins are welcome! <br />
                            The suggested donation is <span color="green">$10.00</span>
                            <span style={{
                                padding: "1px",
                                fontStyle: "italic",
                                display: "block"
                            }}>Please be kind and shut off your phone.</span>
                        </p>
                        <button id="successBtn"
                            style={{
                                width: "150px",
                                height: "51px",
                                color: "#fff",
                                background: "#1FD655",
                                border: "none",
                                borderRadius: "10px",
                            }}
                            onClick={close}
                        >
                            Done
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default WalkinPopUp;
